.section-component {
    padding-top: 8rem;
    padding-bottom: 8rem;

    &:first-child {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

@include media-breakpoint-down(lg) {
    .section-component {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}
