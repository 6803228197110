.participants-component {
    .participant {
        margin-top: 1rem;
        .number {
            color: $secondary;
            font-size: 2.5rem;
            font-family: "Teko", sans-serif;

            @include media-breakpoint-up(lg) {
                font-size: 3rem;
            }
        }
        span {
            color: white;
        }
    }

    .margin-top {
        margin-top: 4rem !important;

        @include media-breakpoint-up(lg) {
            margin-top: 6rem !important;
        }
    }
}
