.findus-text {
    margin-bottom: 5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    p {
        width: auto;

        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }
}

.map-box {
    height: 450px;
    background-color: $secondary;
    position: relative;

    .map {
        @include media-breakpoint-up(lg) {
            height: 450px;
            background-color: white;
            position: absolute;
            top: -45px;
            left: -45px;
            width: 100%;
        }
    }
}
