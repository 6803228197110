table {
	border-collapse: collapse;
	width: 100%;
	border: 2px solid white;
}
th,
td {
	border: 1px solid white;
	padding: 8px;
}
