/* General menu*/
.header {
    background-color: rgba(34, 79, 90, 0.8);
    transition: background-color 0.3s ease-in-out;
}

.scrolled {
    background-color: $primary;
}

.active {
    color: red !important;
    font-weight: bold;
}

.nav-link {
    display: flex !important;
    align-items: center !important;
    margin: auto;
    font-size: 20px !important;
    padding-left: 1rem !important;

    &:hover {
        color: $secondary !important;
        cursor: pointer;
    }

    @include media-breakpoint-up(xl) {
        font-size: 16px !important;
    }
}

.logo {
    width: 70px;
    @include media-breakpoint-up(xl) {
        width: 100px;
    }
}

.socials {
    width: 25px;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
        transition: 0.3s;
    }
}

/*Desktop*/

/*Mobile*/
.offcanvas {
    background-color: $primary !important;
    max-width: 65% !important;

    .offcanvas-body {
        padding-top: 5rem;
    }
}

.offcanvas.show {
    overflow-y: auto;
}

.navbar-toggler {
    border: none !important;
    padding: 0 !important;
}
.navbar-toggler-icon {
    background-image: url(../../img/hamburger.png) !important;
    border: none !important;
}

.btn-close {
    background-image: url(../../img/close.svg) !important;
    opacity: 1 !important;
}

/*How to display the different menus*/
/* show offcanvas menu on mobile */
.header .navbar-collapse {
    display: none;
}

/* hide offcanvas menu on desktop */
@include media-breakpoint-up(xl) {
    .header .offcanvas {
        display: none;
    }
}

/* show regular menu on desktop */
@include media-breakpoint-up(xl) {
    .header .navbar-collapse {
        display: flex !important;
    }
}

/* hide regular menu on mobile */
.header .navbar-collapse {
    display: none !important;
}

@include media-breakpoint-up(xl) {
    .header .navbar-collapse {
        display: flex !important;
    }
}
