.footer-component {
    padding-top: 4rem;
    .divider {
        border-bottom: 2px solid $secondary;
        width: 80%;
    }
    img {
        width: 150px;
        padding: 5rem 0;
    }

    .footer-bottom {
        background-color: $secondary;
        padding: 1rem;

        p {
            color: $primary;
            font-weight: 700;
        }
    }
}
