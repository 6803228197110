@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Teko:wght@300;400;500;600;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Teko", sans-serif;
    margin-top: 1em !important;
    margin-bottom: 0.25em !important;
    color: $secondary !important;
    text-transform: uppercase;
    font-weight: 700 !important;

    &:first-child {
        margin-top: 0 !important;
    }
}

p {
    font-family: "Inter", sans-serif;
    margin-top: 0 !important;
    line-height: 28px;
    color: white;
}

h1,
.h1 {
    font-size: 35px !important;

    @include media-breakpoint-up(lg) {
        font-size: 68px !important;
    }
}

h2,
.h2 {
    font-size: 40px !important;
    @include media-breakpoint-up(lg) {
        font-size: 70px !important;
    }
}
h3,
.h3 {
    font-size: 24px;
    @include media-breakpoint-up(lg) {
        font-size: 37px !important;
    }
}
h4,
.h4 {
    font-size: 20px !important;
    @include media-breakpoint-up(lg) {
        font-size: 28px !important;
    }
}
h5,
.h5 {
    font-size: 16px !important;
    @include media-breakpoint-up(lg) {
        font-size: 21px !important;
    }
}

p {
    font-size: 16px !important;
    @include media-breakpoint-up(lg) {
        font-size: 16px !important;
    }
}

a {
    font-weight: 600 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    color: #fff !important;
    font-family: "Inter", sans-serif !important;
}
