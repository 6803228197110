.whatistrial-component {
    .whatistrial-text {
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
    img {
        margin-bottom: 1.5rem;
    }
}
