.section-about {
    background: $secondary;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include media-breakpoint-up(lg) {
        background: linear-gradient(to right top, $primary 35%, $secondary 35%);
        padding-top: 10rem;
        padding-bottom: 10rem;
        margin-bottom: 5rem;
    }

    .about-component {
        h2,
        p {
            color: $primary !important;
        }
    }
}

.participants-btn {
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: 25%;
    }
}
