.primary-btn {
    color: $primary !important;
    background-color: $secondary;
    border-radius: 40px;
    padding: 0.9rem 1.5em;
    transition: 0.3s;

    &:hover {
        background-color: #5ff3a4;
        cursor: pointer;
    }
}

.secondary-btn {
    color: $secondary !important;
    background-color: $primary;
    border-radius: 40px;
    padding: 0.9rem 1.5em;
    transition: 0.3s;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}
