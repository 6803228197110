.hero-component {
    background-repeat: no-repeat;
    height: 100vh;
    background-image: url(../../img/aletrial.jpg); /* Chrome 10-25, Safari 5.1-6 */
    background-size: cover;
    background-position: center top;
    margin-top: -116px;

    @include media-breakpoint-up(lg) {
        margin-top: -180px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: -198px;
    }

    img {
        width: 250px;
        @include media-breakpoint-up(xl) {
            width: 450px;
        }
    }
}

/**** Arrow ****/
.box {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 4s infinite;
}

.box span:nth-child(2) {
    animation-delay: -0.2s;
}

.box span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
